import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { cloneDeep, isEqual, isEqualWith } from "lodash";
import uuidv4 from "uuid/v4";

import { calculateMenuProductPrice, buildBundleModifications, reCalculateModificationPrice } from "TempUtils";
import { calculateBundleProductAddonPrice } from "Utils";
import { calculateBundleModsPrice } from "PriceUtils";
import { GET_CART } from "../queries/Query";
import { GET_POS_CARTS } from "../queries/CartQueries";
import { GET_EXPRESS_CART } from "../queries/PosQueries";
import { DEFAULT_SELECTED_POS, DEFAULT_SELECTED_TERMINAL } from "Constants";
import { getVatAmount } from "TempUtils/PriceCalculations";
import { getAddonPrice } from "../../admin/utils/TextFormat";
import { mothershipLink } from "./sharedApolloConfigs";

const DECREMENT = "decrement";
const INCREMENT = "increment";

const cache = new InMemoryCache({ addTypename: false });

export const defaults = {
    expressCart: {
        __typename: "ExpressCart",
        orderProducts: [],
        takeAway: null,
        tableMeta: null
    },
    selectedTerminal: DEFAULT_SELECTED_TERMINAL,
    selectedPos: DEFAULT_SELECTED_POS,
    shoppingCart: {
        __typename: "ShoppingCart",
        cartProducts: [],
        shops: []
    },
    posCart: {
        __typename: "PosCart",
        orderWindows: [
            {
                __typename: "OrderWindows",
                orderWindowId: 0,
                postponedPayment: false,
                cartProducts: []
            }
        ]
    },
    selectedCompany: {
        __typename: "Company",
        id: null,
        organisationNumber: null,
        name: "",
        vatRates: [],
        settings: {
            posEnabled: false,
            onlineEnabled: false
        }
    },
    selectedShop: {
        __typename: "Shop",
        id: null,
        shop: null
    },
    authenticatedUser: {
        __typename: "AuthenticatedUser",
        userId: null,
        username: null,
        companyId: null,
        roles: [],
        token: null
    },
    orderHistory: {
        __typename: "OrderHistory",
        orders: []
    },
    userSessionTTL: {
        __typename: "UserSessionTTL",
        ttlTimeoutMs: null
    }
};

const clearEmptyMods = mods =>
    !!mods &&
    mods.sizes &&
    Object.values(mods.sizes).every(m => !m) &&
    mods.flavours &&
    Object.values(mods.flavours).every(m => !m);

export const compareBundleProductItems = (orderProductA, orderProductB) => {
    const refBundleProductIdA = !!orderProductA.refBundleProductId ? orderProductA.refBundleProductId : "";
    const refBundleProductIdB = !!orderProductB.refBundleProductId ? orderProductB.refBundleProductId : "";

    const refBundleProductBundleProductItemsA = orderProductA.selectedBundleProductItems
        ? orderProductA.selectedBundleProductItems.map(item => ({
              ...item,
              modifications: clearEmptyMods(item.modifications) ? null : item.modifications
          }))
        : null;
    const refBundleProductBundleProductItemsB = orderProductB.selectedBundleProductItems
        ? orderProductB.selectedBundleProductItems.map(item => ({
              ...item,
              modifications: clearEmptyMods(item.modifications) ? null : item.modifications
          }))
        : null;

    const refProductIdA = orderProductA.refProductId ? orderProductA.refProductId : "";
    const refProductIdB = orderProductB.refProductId ? orderProductB.refProductId : "";

    const refModificationsA = clearEmptyMods(orderProductA.modifications) ? null : orderProductA.modifications;
    const refModificationsB = clearEmptyMods(orderProductB.modifications) ? null : orderProductB.modifications;

    if (
        orderProductA.name === orderProductB.name &&
        refProductIdA === refProductIdB &&
        refBundleProductIdA === refBundleProductIdB &&
        orderProductA.unitPrice === orderProductB.unitPrice &&
        orderProductA.refProductCategoryId === orderProductB.refProductCategoryId &&
        isEqual(refModificationsA, refModificationsB) &&
        isEqual(refBundleProductBundleProductItemsA, refBundleProductBundleProductItemsB)
    ) {
        return true;
    }
    return false;
};

export const compareOrderProduct = (orderProductA, orderProductB) => {
    const refBundleProductIdA = !!orderProductA.refBundleProductId ? orderProductA.refBundleProductId : "";
    const refBundleProductIdB = !!orderProductB.refBundleProductId ? orderProductB.refBundleProductId : "";

    const refBundleProductBundleProductItemsA = orderProductA.selectedBundleProductItems
        ? orderProductA.selectedBundleProductItems
        : null;
    const refBundleProductBundleProductItemsB = orderProductB.selectedBundleProductItems
        ? orderProductB.selectedBundleProductItems
        : null;

    const refProductIdA = orderProductA.refProductId ? orderProductA.refProductId : "";
    const refProductIdB = orderProductB.refProductId ? orderProductB.refProductId : "";

    const refModificationsA = orderProductA.modifications ? orderProductA.modifications : null;
    const refModificationsB = orderProductB.modifications ? orderProductB.modifications : null;

    const refAddonsA = orderProductA.addons ? orderProductA.addons : null;
    const refAddonsB = orderProductB.addons ? orderProductB.addons : null;

    const refCommentA = orderProductA.comment ? orderProductA.comment : "";
    const refCommentB = orderProductB.comment ? orderProductB.comment : "";

    if (
        orderProductA.name === orderProductB.name &&
        refProductIdA === refProductIdB &&
        refBundleProductIdA === refBundleProductIdB &&
        orderProductA.unitPrice === orderProductB.unitPrice &&
        orderProductA.refProductCategoryId === orderProductB.refProductCategoryId &&
        isEqual(refModificationsA, refModificationsB) &&
        isEqual(refBundleProductBundleProductItemsA, refBundleProductBundleProductItemsB) &&
        isEqual(refAddonsA, refAddonsB) &&
        isEqual(refCommentA, refCommentB)
    ) {
        return true;
    }
    return false;
};

const buildMenuOrderProduct = menuProduct => {
    const { refProduct, addons } = menuProduct;
    const addonPrice = getAddonPrice(addons);
    const unitPrice = menuProduct.price ? menuProduct.price : refProduct.defaultPrice;
    const productModifications = menuProduct.modifications ? menuProduct.modifications : refProduct.modifications;

    const menuProductPrice = calculateMenuProductPrice(menuProduct);
    const vatAmount = getVatAmount(refProduct.vatRate, menuProductPrice);

    const orderProduct = {
        name: refProduct.name,
        refProductId: refProduct.id,
        refBundleProductId: null,
        refProductCategoryId: refProduct.refProductCategoryId,
        menuCategoryId: refProduct.menuCategoryId,
        modifications: productModifications,
        menuProductModifications: refProduct.modifications,
        menuProductPrice: unitPrice,
        cartDisplayUnitPrice: menuProductPrice - addonPrice,
        menuBundleModifications: null,
        menuBundleProductPrice: null,
        selectedBundleProductItems: null,
        bundleProductCategories: null,
        priceType: refProduct.priceType,
        totalPrice: menuProductPrice,
        totalNetPrice: menuProductPrice - vatAmount,
        unitPrice: menuProductPrice,
        vatRate: refProduct.vatRate,
        quantity: 1,
        weight: 0,
        cartProductId: refProduct.toUpdate ? refProduct.cartProductId : uuidv4(), // If the product will be updated, no need to create new id
        upsell: menuProduct.upsell || false,
        addons: menuProduct.addons ? menuProduct.addons : [],
        comment: ""
    };

    return orderProduct;
};

const buildBundleProduct = (menuBundleProduct, menuBundleProductItems, bundleProductCategories) => {
    const menuBundleModifications = buildBundleModifications(menuBundleProductItems);
    const addonModsPrices = calculateBundleModsPrice(menuBundleModifications);
    const unitPrice = menuBundleProduct.price
        ? menuBundleProduct.price
        : menuBundleProduct.refBundleProduct.defaultPrice;

    const totalPrice = unitPrice + addonModsPrices;
    const vatAmount = getVatAmount(menuBundleProduct.refBundleProduct.vatRate, totalPrice);

    return {
        name: menuBundleProduct.refBundleProduct.name,
        refProductId: null,
        refBundleProductId: menuBundleProduct.refBundleProduct.id,
        menuCategoryId: menuBundleProduct.refBundleProduct.menuCategoryId,
        refProductCategoryId: menuBundleProduct.refBundleProduct.refProductCategoryId,
        selectedBundleProductItems: menuBundleModifications,
        bundleProductCategories,
        modifications: null,
        menuProductModifications: null,
        menuProductPrice: null,
        menuBundleProductPrice: unitPrice,
        cartDisplayUnitPrice: unitPrice,
        totalPrice: totalPrice,
        totalNetPrice: totalPrice - vatAmount, //unitPrice * (1 - menuBundleProduct.refBundleProduct.vatRate / 100),
        unitPrice: totalPrice,
        priceType: menuBundleProduct.refBundleProduct.priceType,
        vatRate: menuBundleProduct.refBundleProduct.vatRate,
        quantity: 1,
        weight: 0,
        cartProductId: uuidv4(),
        upsell: false,
        comment: ""
    };
};

export const findAndMergeCartProducts = (foundAndReplacedProducts, productToUpdate) => {
    const uniqueCartProducts = [];
    const cartProductToMerge = [];

    foundAndReplacedProducts.forEach(cartProduct => {
        if (isEqualWith(cartProduct, productToUpdate, compareOrderProduct)) {
            cartProductToMerge.push(cartProduct);
        } else {
            uniqueCartProducts.push(cartProduct);
        }
    });
    if (cartProductToMerge.length > 1) {
        const mergedProduct = cartProductToMerge.reduce((acc, curr, idx) => {
            if (idx === 0) {
                return curr;
            } else {
                const totalPrice = acc.totalPrice + curr.totalPrice;
                const totalVatAmount = getVatAmount(curr.vatRate, totalPrice);
                return {
                    ...acc,
                    quantity: acc.quantity + curr.quantity,
                    totalPrice: totalPrice,
                    totalNetPrice: totalPrice - totalVatAmount
                };
            }
        }, {});
        return [mergedProduct, ...uniqueCartProducts];
    }
    return foundAndReplacedProducts;
};

export const apolloClient = new ApolloClient({
    cache,
    link: mothershipLink,
    resolvers: {
        Query: {},
        Mutation: {
            setWhereToEat: (_, { takeAway }, { cache }) => {
                const data = {
                    expressCart: {
                        __typename: "ExpressCart",
                        takeAway: takeAway
                    }
                };

                cache.writeData({ data });
                return null;
            },
            setTableMeta: (_, { tableName }, { cache }) => {
                const data = {
                    expressCart: {
                        __typename: "TableMeta",
                        tableMeta: { name: tableName }
                    }
                };
                cache.writeData({ data });
                return true;
            },
            updateBundleProductModification: (_, { menuProduct, newModifications, refProductToUpdate }, { cache }) => {
                // menuProduct == orderProduct!!!
                const existingProducts = cache.readQuery({
                    query: GET_EXPRESS_CART
                });

                const bundleProductAddonsPrice = menuProduct.addons ? getAddonPrice(menuProduct.addons) : 0;

                const newRefProduct = {
                    addons: refProductToUpdate.addons ? refProductToUpdate.addons : [],
                    name: refProductToUpdate.name,
                    refProductId: refProductToUpdate.id,
                    refProductCategoryId: refProductToUpdate.refProductCategoryId,
                    kdsUnitDisplayName: refProductToUpdate.kdsUnitDisplayName
                        ? refProductToUpdate.kdsUnitDisplayName
                        : "",
                    modifications: newModifications,
                    bundleProductCategoryId: menuProduct.oldBundleItem.bundleProductCategoryId,
                    price: refProductToUpdate.price
                };

                const updatedData = existingProducts.expressCart.orderProducts
                    .map(orderProd => {
                        if (orderProd.cartProductId === menuProduct.cartProductId) {
                            const newSelectedBundleProductItems = orderProd.selectedBundleProductItems.map(
                                (item, i) => {
                                    if (
                                        item.refProductId === menuProduct.oldBundleItem.refProductId &&
                                        isEqual(item.modifications, menuProduct.oldBundleItem.modifications) &&
                                        menuProduct.oldBundleItem.index === i
                                    ) {
                                        return newRefProduct;
                                    } else {
                                        return item;
                                    }
                                }
                            );
                            const addonPrice = calculateBundleProductAddonPrice(newSelectedBundleProductItems);
                            const bundleModsPrice = calculateBundleModsPrice(newSelectedBundleProductItems);

                            const unitPrice =
                                bundleModsPrice +
                                orderProd.menuBundleProductPrice +
                                addonPrice +
                                bundleProductAddonsPrice;
                            const totalPrice = unitPrice * orderProd.quantity;
                            const vatAmount = getVatAmount(orderProd.vatRate, totalPrice);

                            const updatedBundle = {
                                ...orderProd,
                                selectedBundleProductItems: newSelectedBundleProductItems,
                                unitPrice,
                                totalPrice,
                                totalNetPrice: totalPrice - vatAmount
                            };

                            return updatedBundle;
                        }
                        return orderProd;
                    })
                    .reduce((acc, next) => {
                        const productIndex = acc.findIndex(product =>
                            isEqualWith(product, next, compareBundleProductItems)
                        );

                        if (productIndex > -1) {
                            acc[productIndex].quantity += next.quantity;
                            const totalPrice = acc[productIndex].unitPrice * acc[productIndex].quantity;
                            acc[productIndex].totalPrice = totalPrice;
                            const totalVatAmount = getVatAmount(next.vatRate, totalPrice);
                            acc[productIndex].totalNetPrice = totalPrice - totalVatAmount;
                        } else {
                            acc.push(next);
                        }
                        return acc;
                    }, []);

                const newData = {
                    expressCart: {
                        __typename: "ExpressCart",
                        orderProducts: updatedData
                    }
                };

                cache.writeQuery({ query: GET_EXPRESS_CART, data: newData });

                return null;
            },
            incrementOrDecrementExpressCartProduct: (_, { orderProduct, typeOfOperation }, { cache }) => {
                const existingProducts = cache.readQuery({
                    query: GET_EXPRESS_CART
                });

                const foundProd = existingProducts.expressCart.orderProducts.find(
                    prevOrderProduct => prevOrderProduct.cartProductId === orderProduct.cartProductId
                );
                let unFrozenProd = cloneDeep(foundProd);
                const aVatAmount = getVatAmount(unFrozenProd.vatRate, unFrozenProd.unitPrice);

                if (typeOfOperation === INCREMENT) {
                    unFrozenProd.quantity++;
                    unFrozenProd.totalPrice = unFrozenProd.unitPrice * unFrozenProd.quantity;
                    unFrozenProd.totalNetPrice = unFrozenProd.totalPrice - aVatAmount * unFrozenProd.quantity;
                } else if (typeOfOperation === DECREMENT) {
                    unFrozenProd.quantity--;
                    unFrozenProd.totalPrice = unFrozenProd.unitPrice * unFrozenProd.quantity;
                    unFrozenProd.totalNetPrice = unFrozenProd.totalPrice - aVatAmount * unFrozenProd.quantity;
                }

                const foundAndReplacedProducts = existingProducts.expressCart.orderProducts.map(orderProd =>
                    orderProd.cartProductId === unFrozenProd.cartProductId ? unFrozenProd : orderProd
                );

                const newData = {
                    expressCart: {
                        __typename: "ExpressCart",
                        orderProducts: foundAndReplacedProducts
                    }
                };

                cache.writeQuery({ query: GET_EXPRESS_CART, data: newData });

                return null;
            },
            updateProductComment: (_, { orderProduct, comment }, { cache }) => {
                const existingProducts = cache.readQuery({ query: GET_EXPRESS_CART });
                const foundProduct = existingProducts.expressCart.orderProducts.find(
                    op => op.cartProductId === orderProduct.cartProductId
                );

                const productToUpdate = cloneDeep(foundProduct);
                productToUpdate.comment = comment;

                let foundAndReplacedProducts = existingProducts.expressCart.orderProducts.map(op =>
                    op.cartProductId === foundProduct.cartProductId ? productToUpdate : op
                );

                if (foundAndReplacedProducts.length > 1) {
                    foundAndReplacedProducts = findAndMergeCartProducts(foundAndReplacedProducts, productToUpdate);
                }
                const newData = {
                    expressCart: {
                        __typename: "ExpressCart",
                        orderProducts: foundAndReplacedProducts
                    }
                };

                cache.writeQuery({ query: GET_EXPRESS_CART, data: newData });
                return null;
            },
            removeExpressCartProduct: (_, { orderProduct }, { cache }) => {
                const existingProducts = cache.readQuery({
                    query: GET_EXPRESS_CART
                });

                const newProducts = existingProducts.expressCart.orderProducts.filter(
                    prevOrderProduct => prevOrderProduct.cartProductId !== orderProduct.cartProductId
                );
                const newData = {
                    expressCart: {
                        __typename: "ExpressCart",
                        orderProducts: newProducts
                    }
                };

                cache.writeQuery({ query: GET_EXPRESS_CART, data: newData });

                return null;
            },
            addBundleToExpressCart: (
                _,
                { menuBundleProduct, menuBundleProductItems, bundleProductCategories },
                { cache }
            ) => {
                const menuBundleModifications = buildBundleModifications(menuBundleProductItems);

                const addonModsPrices = calculateBundleModsPrice(menuBundleModifications);
                const addonPrice = calculateBundleProductAddonPrice(menuBundleModifications);
                let addonsOnBundlePrice = 0;
                if (!!menuBundleProduct.addons?.length) {
                    addonsOnBundlePrice = menuBundleProduct.addons.reduce(
                        (acc, next) => acc + next.price * next.quantity,
                        0
                    );
                }

                const unitPrice = menuBundleProduct.price
                    ? menuBundleProduct.price
                    : menuBundleProduct.refBundleProduct.defaultPrice;
                const totalPrice = unitPrice + addonModsPrices + addonPrice + addonsOnBundlePrice;
                const vatAmount = getVatAmount(menuBundleProduct.refBundleProduct.vatRate, totalPrice);

                const bundleOrderProduct = {
                    addons: menuBundleProduct.addons ?? [],
                    name: menuBundleProduct.refBundleProduct.name,
                    refProductId: null,
                    refBundleProductId: menuBundleProduct.refBundleProduct.id,
                    menuCategoryId: menuBundleProduct.menuCategoryId,
                    refProductCategoryId: menuBundleProduct.refBundleProduct.refProductCategoryId,
                    selectedBundleProductItems: menuBundleModifications,
                    bundleProductCategories,
                    modifications: null,
                    menuProductModifications: null,
                    menuProductPrice: null,
                    menuBundleProductPrice: unitPrice,
                    cartDisplayUnitPrice: unitPrice,
                    totalPrice,
                    totalNetPrice: totalPrice - vatAmount,
                    unitPrice: totalPrice,
                    priceType: menuBundleProduct.refBundleProduct.priceType,
                    vatRate: menuBundleProduct.refBundleProduct.vatRate,
                    quantity: 1,
                    weight: 0,
                    cartProductId: uuidv4(),
                    comment: "",
                    upsell: false
                };

                let existingProducts = cache.readQuery({ query: GET_EXPRESS_CART });
                let productExists = false;
                let newOrderProducts;

                existingProducts.expressCart.orderProducts.forEach(product => {
                    if (isEqualWith(product, bundleOrderProduct, compareOrderProduct)) {
                        productExists = true;
                        product.quantity++;
                        product.totalPrice += unitPrice + addonModsPrices + addonPrice + addonsOnBundlePrice;
                        product.totalNetPrice = product.totalPrice - vatAmount * product.quantity;
                    }
                });

                if (!productExists) {
                    newOrderProducts = existingProducts.expressCart.orderProducts.concat([bundleOrderProduct]);
                } else {
                    newOrderProducts = existingProducts.expressCart.orderProducts;
                }

                const newData = {
                    expressCart: {
                        __typename: "ExpressCart",
                        orderProducts: newOrderProducts
                    }
                };

                cache.writeQuery({ query: GET_EXPRESS_CART, data: newData });

                return null;
            },
            addMenuProductToExpressCart: (_, { menuProduct }, { cache }) => {
                menuProduct.refProduct = {
                    ...menuProduct.refProduct,
                    menuCategoryId: menuProduct.menuCategoryId
                };
                const { refProduct } = menuProduct;
                let orderProduct = buildMenuOrderProduct(menuProduct);

                let existingProducts = cache.readQuery({ query: GET_EXPRESS_CART });
                let productExists = false;
                let newOrderProducts;

                existingProducts.expressCart.orderProducts.forEach(product => {
                    if (isEqualWith(product, orderProduct, compareOrderProduct)) {
                        productExists = true;
                        product.quantity += 1;
                        product.menuBundleModifications = null;
                        product.totalPrice += calculateMenuProductPrice(menuProduct);
                        const totalVatAmount = getVatAmount(refProduct.vatRate, product.totalPrice);
                        product.totalNetPrice = product.totalPrice - totalVatAmount;
                    }
                });

                if (!productExists) {
                    newOrderProducts = existingProducts.expressCart.orderProducts.concat(orderProduct);
                } else {
                    newOrderProducts = existingProducts.expressCart.orderProducts;
                }

                const newData = {
                    expressCart: {
                        __typename: "ExpressCart",
                        orderProducts: newOrderProducts
                    }
                };
                cache.writeQuery({ query: GET_EXPRESS_CART, data: newData });

                return null;
            },
            updateExpressCheckoutBundleProduct: (_, { bundleOrderProduct, newMenuBundleProductItems }, { cache }) => {
                let existingProducts = cache.readQuery({ query: GET_EXPRESS_CART });
                let clonedBundleProduct = cloneDeep(bundleOrderProduct);

                const updateBundleProductItems = buildBundleModifications(newMenuBundleProductItems);
                const addonModsPrices = calculateBundleModsPrice(updateBundleProductItems);
                const productToUpdate = existingProducts.expressCart.orderProducts.find(
                    orderProduct => orderProduct.cartProductId === clonedBundleProduct.cartProductId
                );

                let productExists = false;
                let newOrderProducts;

                existingProducts.expressCart.orderProducts.forEach(orderProduct => {
                    if (orderProduct.cartProductId === orderProduct.cartProductId) {
                        const unitPrice = addonModsPrices + bundleOrderProduct.menuBundleProductPrice;
                        const totalPrice = unitPrice * orderProduct.quantity;
                        const totalVatAmount = getVatAmount(orderProduct.vatRate, totalPrice);
                        productExists = true;
                        productToUpdate.cartDisplayUnitPrice = unitPrice;
                        productToUpdate.selectedBundleProductItems = updateBundleProductItems;
                        productToUpdate.totalPrice = unitPrice * orderProduct.quantity;
                        productToUpdate.unitPrice = unitPrice;
                        productToUpdate.totalNetPrice = totalPrice - totalVatAmount;
                    }
                });

                if (!productExists) {
                    newOrderProducts = existingProducts.expressCart.orderProducts.concat(productToUpdate);
                } else {
                    newOrderProducts = existingProducts.expressCart.orderProducts;
                }

                const newData = {
                    expressCart: {
                        __typename: "ExpressCart",
                        orderProducts: newOrderProducts
                    }
                };

                cache.writeQuery({ query: GET_EXPRESS_CART, data: newData });

                return null;
            },
            updateExpressCartOrderProductModifications: (
                _,
                { orderProduct, newModifications, newAddons },
                { cache }
            ) => {
                let existingProducts = cache.readQuery({ query: GET_EXPRESS_CART });

                const newOrderProducts = existingProducts.expressCart.orderProducts
                    .map(product => {
                        if (product.cartProductId === orderProduct.refProduct.cartProductId) {
                            const newAddonPrice = newAddons ? getAddonPrice(newAddons) : 0;

                            const unitPrice =
                                reCalculateModificationPrice(
                                    newModifications,
                                    orderProduct.refProduct.menuProductPrice // 15 (10 + 5)
                                ) + newAddonPrice;

                            const totalPrice = unitPrice * product.quantity;
                            const totalVatAmount = getVatAmount(orderProduct.refProduct.vatRate, totalPrice);
                            product.modifications = newModifications;
                            product.cartDisplayUnitPrice = unitPrice - newAddonPrice;
                            product.menuProductModifications = orderProduct.refProduct.menuProductModifications;
                            product.totalPrice = totalPrice;
                            product.unitPrice = unitPrice;
                            product.totalNetPrice = totalPrice - totalVatAmount;
                            product.addons = newAddons;
                        }
                        return product;
                    })
                    .reduce((acc, next) => {
                        const productIndex = acc.findIndex(product => isEqualWith(product, next, compareOrderProduct));

                        if (productIndex > -1) {
                            acc[productIndex].quantity += next.quantity;
                            acc[productIndex].totalPrice += calculateMenuProductPrice(next);
                            const totalVatAmount = getVatAmount(next.vatRate, acc[productIndex].totalPrice);
                            acc[productIndex].totalNetPrice = acc[productIndex].totalPrice - totalVatAmount;
                        } else {
                            acc.push(next);
                        }
                        return acc;
                    }, []);

                const newData = {
                    expressCart: {
                        __typename: "ExpressCart",
                        orderProducts: newOrderProducts
                    }
                };

                cache.writeQuery({
                    query: GET_EXPRESS_CART,
                    data: newData
                });

                return null;
            },
            updateSelectedPos: (_, { pos }, { cache }) => {
                cache.writeData({
                    data: {
                        selectedPos: {
                            ...pos,
                            __typename: "Pos"
                        }
                    }
                });
                return null;
            },
            updateSelectedTerminal: (_, { terminal }, { cache }) => {
                cache.writeData({
                    data: {
                        selectedTerminal: {
                            ...terminal,
                            __typename: "Terminal"
                        }
                    }
                });
                return null;
            },
            updateSelectedShop: (_, { shop }, { cache }) => {
                cache.writeData({
                    data: {
                        selectedShop: {
                            __typename: "Shop",
                            id: shop.id,
                            shop: shop
                        }
                    }
                });
                return null;
            },
            updateSelectedCompany: (_, { company }, { cache }) => {
                cache.writeData({
                    data: {
                        selectedCompany: {
                            __typename: "Company",
                            id: company.id,
                            organisationNumber: company.organisationNumber,
                            name: company.name,
                            vatRates: company.vatRates,
                            settings: company.settings
                        },
                        selectedShop: {
                            __typename: "Shop",
                            id: null,
                            shop: null
                        }
                    }
                });
                return null;
            },
            removeOrderProductFromCart: (_, { cartProductId }, { cache }) => {
                const query = GET_CART;
                const existingProducts = cache.readQuery({ query });
                const { cartProducts, shops } = existingProducts.shoppingCart;

                const newCartProducts = cartProducts.filter(cartProduct => cartProduct.cartProductId !== cartProductId);

                const newData = {
                    shoppingCart: {
                        __typename: "ShoppingCart",
                        cartProducts: newCartProducts,
                        shops
                    }
                };

                cache.writeQuery({ query, data: newData });
                return null;
            },
            updateOrderProductQuanityInCart: (_, { orderProduct, newQuantity }, { cache }) => {
                const query = GET_CART;
                const existingProducts = cache.readQuery({ query });
                const { cartProducts, shops } = existingProducts.shoppingCart;

                const foundAndUpdatedOrderProduct = cartProducts.map(cartProduct => {
                    if (cartProduct.cartProductId === orderProduct.cartProductId) {
                        const totalPrice = cartProduct.unitPrice * newQuantity;
                        const vatAmount = getVatAmount(cartProduct.vatRate, totalPrice);
                        return {
                            ...cartProduct,
                            quantity: newQuantity,
                            totalPrice,
                            totalNetPrice: totalPrice - vatAmount
                        };
                    } else {
                        return cartProduct;
                    }
                });

                const newData = {
                    shoppingCart: {
                        __typename: "ShoppingCart",
                        cartProducts: foundAndUpdatedOrderProduct,
                        shops
                    }
                };

                cache.writeQuery({ query, data: newData });
                return null;
            },
            addProductToCart: (_, { menuProduct, shop }, { cache }) => {
                const query = GET_CART;
                let existingProducts = cache.readQuery({ query });

                let orderProduct = buildMenuOrderProduct({ refProduct: menuProduct });
                orderProduct = { ...orderProduct, shopId: shop.id };

                let productExists = false;
                let newCartProducts = [...existingProducts.shoppingCart.cartProducts];

                newCartProducts.forEach(cartProduct => {
                    if (isEqualWith(cartProduct, orderProduct, compareOrderProduct)) {
                        productExists = true;
                        cartProduct.quantity++;
                        cartProduct.totalPrice += calculateMenuProductPrice({
                            refProduct: menuProduct
                        });
                        const totalVatAmount = getVatAmount(menuProduct.vatRate, cartProduct.totalPrice);
                        cartProduct.totalNetPrice = cartProduct.totalPrice - totalVatAmount;
                    }
                });

                if (!productExists) {
                    newCartProducts = existingProducts.shoppingCart.cartProducts.concat(orderProduct);
                }

                const shopExists = existingProducts.shoppingCart.shops.find(cartShop => cartShop.id == shop.id);
                const data = {
                    shoppingCart: {
                        __typename: "ShoppingCart",
                        cartProducts: newCartProducts,
                        shops: shopExists
                            ? existingProducts.shoppingCart.shops
                            : existingProducts.shoppingCart.shops.concat([shop])
                    }
                };

                cache.writeQuery({ query, data });
                return null;
            },
            addPosCart: (_, __, { cache }) => {
                const query = GET_POS_CARTS;
                const previous = cache.readQuery({ query });

                // Checks all previous orderWindowIds for the highest and later increments it
                const orderWindowId = Math.max(...previous.posCart.orderWindows.map(o => o.orderWindowId)) + 1;

                const emptyOrderWindow = {
                    orderWindowId,
                    postponedPayment: false,
                    cartProducts: []
                };
                const data = {
                    posCart: {
                        __typename: "PosCart",
                        orderWindows: previous.posCart.orderWindows.concat([emptyOrderWindow])
                    }
                };

                cache.writeQuery({ query, data });

                return orderWindowId;
            },
            changeCart: (_, __, { cache }) => {
                const query = GET_POS_CARTS;
                const previous = cache.readQuery({ query }); // This array includes the new orderWindow
                const newCart = previous.posCart.orderWindows[previous.posCart.orderWindows.length - 1];

                return newCart;
            },
            setPaymentPostponed: (_, { orderWindowId }, { cache }) => {
                const query = GET_POS_CARTS;
                const previous = cache.readQuery({ query });

                const updatedOrderWindows = previous.posCart.orderWindows.map(window =>
                    window.orderWindowId === orderWindowId ? { ...window, postponedPayment: true } : window
                );

                const newData = {
                    posCart: {
                        __typename: "PosCart",
                        orderWindows: updatedOrderWindows
                    }
                };

                cache.writeQuery({ query, data: newData });
                return null;
            },
            addToPosCart: (
                _,
                { menuProduct, orderWindowId, newModifications, productAmount, menuBundleItems },
                { cache }
            ) => {
                const query = GET_POS_CARTS;
                const previous = cache.readQuery({ query });

                let unFrozenMenuProduct = cloneDeep(menuProduct);

                if (newModifications) {
                    unFrozenMenuProduct.modifications = newModifications;
                }

                if (!unFrozenMenuProduct.refProduct) unFrozenMenuProduct.refProduct = null;

                unFrozenMenuProduct = Array(productAmount ? productAmount : 1).fill(unFrozenMenuProduct);
                const newPosCart = {
                    posCart: {
                        __typename: "PosCart",
                        orderWindows: previous.posCart.orderWindows.map(window =>
                            window.orderWindowId === orderWindowId
                                ? {
                                      ...window,
                                      cartProducts: unFrozenMenuProduct.concat(window.cartProducts)
                                  }
                                : window
                        )
                    }
                };

                cache.writeQuery({ query, data: newPosCart });
                return null;
            },
            removeFromPosCart: (_, { menuProduct, orderWindowId }, { cache }) => {
                const query = GET_POS_CARTS;
                const previous = cache.readQuery({
                    query,
                    variables: { orderWindowId }
                });

                const orderWindowIndex = previous.posCart.orderWindows.findIndex(
                    orderWindow => orderWindow.orderWindowId == orderWindowId
                );

                const orderWindowProducts = previous.posCart.orderWindows[orderWindowIndex].cartProducts;

                const idx = orderWindowProducts.reverse().findIndex(x => {
                    if (x.id === menuProduct.id) {
                        if (x.modifications) {
                            return isEqual(x.modifications, menuProduct.modifications);
                        }
                        return true;
                    }
                });

                const removedProduct = orderWindowProducts.filter((_, i) => i !== idx).reverse();
                const newData = { ...previous };

                newData.posCart.orderWindows[orderWindowIndex].cartProducts = removedProduct;

                const data = {
                    posCart: {
                        __typename: "PosCart",
                        orderWindows: newData.posCart.orderWindows
                    }
                };
                cache.writeQuery({ query, data });
                return null;
            },

            removePosListRow: (_, { orderWindowId, menuProduct }, { cache }) => {
                const query = GET_POS_CARTS;
                const previous = cache.readQuery({ query });
                const orderWindowToUpdate = previous.posCart.orderWindows.find(
                    window => window.orderWindowId === orderWindowId
                );
                const updatedCartProducts = orderWindowToUpdate.cartProducts.filter(menuProd => {
                    if (menuProd.modifications || menuProd.selectedBundleProductItems) {
                        return JSON.stringify(menuProd) !== JSON.stringify(menuProduct);
                    } else {
                        return menuProd.id !== menuProduct.id;
                    }
                });

                const newOrderWindow = {
                    ...orderWindowToUpdate,
                    orderWindowId: orderWindowToUpdate.orderWindowId,
                    cartProducts: updatedCartProducts
                };
                const updatedOrderWindows = previous.posCart.orderWindows.map(ow =>
                    ow.orderWindowId === orderWindowId ? newOrderWindow : ow
                );

                const newdata = {
                    posCart: {
                        __typename: "PosCart",
                        orderWindows: updatedOrderWindows
                    }
                };
                cache.writeQuery({ query, data: newdata });
                return null;
            },
            removeOrderTab: (_, { orderWindowId }, { cache }) => {
                const query = GET_POS_CARTS;
                const previous = cache.readQuery({ query });
                const newData = {
                    posCart: {
                        __typename: "PosCart",
                        orderWindows: previous.posCart.orderWindows.filter(e => e.orderWindowId !== orderWindowId)
                    }
                };

                cache.writeQuery({ query, data: newData });
                return null;
            },
            emptyExpressCart: (_, __, { cache }) => {
                const data = {
                    expressCart: {
                        __typename: "ExpressCart",
                        orderProducts: []
                    }
                };

                cache.writeData({ data });

                return null;
            },
            emptyCart: (_, __, { cache }) => {
                const data = {
                    shoppingCart: {
                        __typename: "ShoppingCart",
                        shops: [],
                        cartProducts: []
                    }
                };
                cache.writeData({ data });
                return null;
            },
            emptyPosCart: (_, { orderWindowId }, { cache }) => {
                const query = GET_POS_CARTS;
                const previous = cache.readQuery({ query });
                const resetOrderWindows = previous.posCart.orderWindows.length === 1;

                const data = {
                    posCart: {
                        __typename: "PosCart",
                        orderWindows: previous.posCart.orderWindows.map(orderWindow =>
                            orderWindow.orderWindowId === orderWindowId
                                ? {
                                      ...orderWindow,
                                      orderWindowId: resetOrderWindows ? 0 : orderWindow.orderWindowId,
                                      cartProducts: [],
                                      postponedPayment: false
                                  }
                                : orderWindow
                        )
                    }
                };

                cache.writeQuery({ query, data });
                return null;
            }
        }
    }
});

cache.writeData({
    data: defaults
});
