export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
    DateTime: any;
};

export type ContactInformation = {
    __typename?: "ContactInformation";
    addressLine?: Maybe<Scalars["String"]>;
    addressLine2?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    country?: Maybe<Scalars["String"]>;
    doorCode?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    floorNumber?: Maybe<Scalars["String"]>;
    lastName?: Maybe<Scalars["String"]>;
    latLng?: Maybe<LatLng>;
    name?: Maybe<Scalars["String"]>;
    personalNumber?: Maybe<Scalars["String"]>;
    phoneNumber?: Maybe<Scalars["String"]>;
    phoneNumber2?: Maybe<Scalars["String"]>;
    postCode?: Maybe<Scalars["Int"]>;
};

export type ContactInformationInput = {
    addressLine?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    lastName: Scalars["String"];
    name: Scalars["String"];
    phoneNumber: Scalars["String"];
    postCode?: Maybe<Scalars["Int"]>;
};

/** How the user chose to eat */
export enum EatingOption {
    Catering = "CATERING",
    EatHere = "EAT_HERE",
    HomeDelivery = "HOME_DELIVERY",
    TakeAway = "TAKE_AWAY"
}

export type Error = {
    __typename?: "Error";
    message?: Maybe<Scalars["String"]>;
    path?: Maybe<Scalars["String"]>;
};

export type GoogleAuthInput = {
    googleCredentials: Scalars["String"];
    merge?: Maybe<Scalars["Boolean"]>;
};

export type Ingredient = {
    __typename?: "Ingredient";
    name: Scalars["String"];
    price: Scalars["String"];
    quantity: Scalars["String"];
};

export type LatLng = {
    __typename?: "LatLng";
    lat: Scalars["String"];
    lng: Scalars["String"];
};

export type LoginInput = {
    email: Scalars["String"];
    password: Scalars["String"];
};

export type LoginOutput = Error | UserAccount;

export type Modification = {
    __typename?: "Modification";
    addonPrice: Scalars["String"];
    name: Scalars["String"];
    price: Scalars["String"];
};

export type Modifications = {
    __typename?: "Modifications";
    flavours?: Maybe<Array<Modification>>;
    sizes?: Maybe<Array<Modification>>;
};

export type Mutation = {
    __typename?: "Mutation";
    addShopToFavorites?: Maybe<Array<Scalars["String"]>>;
    deleteUserAccount: Scalars["Boolean"];
    googleSignin: Array<LoginOutput>;
    importOrdersToUserAccount: Scalars["Int"];
    loginUserAccount: Array<LoginOutput>;
    logoutUserAccount: Scalars["Boolean"];
    registerUserAccount?: Maybe<Array<Error>>;
    resetUserAccountPassword?: Maybe<Error>;
    updateUserAccount: Array<UserAccountErrorOutput>;
    userAccountForgotPassword?: Maybe<Error>;
    userAccountUpdatePassword?: Maybe<Error>;
    verifyUserAccount: Array<LoginOutput>;
};

export type MutationAddShopToFavoritesArgs = {
    favoriteShopIds: Array<Scalars["String"]>;
};

export type MutationDeleteUserAccountArgs = {
    userLanguage: UserLanguage;
};

export type MutationGoogleSigninArgs = {
    input: GoogleAuthInput;
};

export type MutationImportOrdersToUserAccountArgs = {
    email: Scalars["String"];
};

export type MutationLoginUserAccountArgs = {
    input: LoginInput;
};

export type MutationRegisterUserAccountArgs = {
    input: RegisterInput;
};

export type MutationResetUserAccountPasswordArgs = {
    input: ResetPasswordInput;
    userLanguage: UserLanguage;
};

export type MutationUpdateUserAccountArgs = {
    input: UserAccountInput;
};

export type MutationUserAccountForgotPasswordArgs = {
    email: Scalars["String"];
    userLanguage: UserLanguage;
};

export type MutationUserAccountUpdatePasswordArgs = {
    input: UpdatePasswordInput;
};

export type MutationVerifyUserAccountArgs = {
    shouldLogin: Scalars["Boolean"];
    token: Scalars["String"];
};

/** Tells us about the status of the order */
export enum OnlineOrderStatus {
    Cancelled = "CANCELLED",
    CancelledPayment = "CANCELLED_PAYMENT",
    ClientAutoDenied = "CLIENT_AUTO_DENIED",
    Confirmed = "CONFIRMED",
    Declined = "DECLINED",
    Denied = "DENIED",
    ErrorDeliveryCancel = "ERROR_DELIVERY_CANCEL",
    ErrorDeliveryCreate = "ERROR_DELIVERY_CREATE",
    FailedPayment = "FAILED_PAYMENT",
    OfflineAutoDenied = "OFFLINE_AUTO_DENIED",
    Pending = "PENDING",
    ShopAutoDenied = "SHOP_AUTO_DENIED"
}

export type Order = {
    __typename?: "Order";
    /** Comment to restaurant */
    comment?: Maybe<Scalars["String"]>;
    /** Comment to driver */
    comment2?: Maybe<Scalars["String"]>;
    contactInformation: ContactInformation;
    deliveryFee: Scalars["Int"];
    discount: Scalars["Boolean"];
    eatingOption?: Maybe<EatingOption>;
    id: Scalars["ID"];
    onlineOrderStatus: OnlineOrderStatus;
    orderNo: Scalars["Int"];
    orderProducts: Array<OrderProduct>;
    orderType: OrderType;
    paymentMethodAmounts: Array<PaymentMethodAmounts>;
    paymentStatus: PaymentStatus;
    purchaseDate: Scalars["DateTime"];
    receiptNumber: Scalars["Int"];
    refundStatus: RefundStatus;
    serviceFee?: Maybe<ServiceFee>;
    shopId?: Maybe<Scalars["String"]>;
    shopName?: Maybe<Scalars["String"]>;
    subscriptionMeta?: Maybe<SubscriptionMeta>;
    tip?: Maybe<Scalars["Float"]>;
    totalAmount: Scalars["Float"];
    totalAmountAddons: Scalars["Float"];
    totalAmountUpsell: Scalars["Float"];
    totalNetAmount: Scalars["Float"];
    userAccountId?: Maybe<Scalars["String"]>;
    userInformation?: Maybe<UserInformation>;
    vatRatesAndAmounts: Array<VatRateAndAmount>;
    webOrderType: WebOrderType;
    webPaymentId: Scalars["String"];
};

export type OrderProduct = {
    __typename?: "OrderProduct";
    addons?: Maybe<Array<Ingredient>>;
    comment?: Maybe<Scalars["String"]>;
    discountId: Scalars["String"];
    discountRate: Scalars["Int"];
    discountValue: Scalars["Int"];
    menuCategoryId: Scalars["String"];
    modifications?: Maybe<Modifications>;
    name: Scalars["String"];
    quantity: Scalars["Int"];
    refBundleProductId?: Maybe<Scalars["String"]>;
    refProductCategoryId: Scalars["String"];
    refProductId?: Maybe<Scalars["String"]>;
    selectedBundleProductItems?: Maybe<Array<SelectedBundleProductItems>>;
    shopId: Scalars["String"];
    totalNetPrice: Scalars["Int"];
    totalPrice: Scalars["Float"];
    unitPrice: Scalars["Float"];
    vatRate: Scalars["Int"];
};

/** Either from web or regular POS */
export enum OrderType {
    Pos = "POS",
    Subscription = "SUBSCRIPTION",
    Web = "WEB"
}

export type PaymentMethodAmounts = {
    __typename?: "PaymentMethodAmounts";
    amount: Scalars["Float"];
    paymentMethod: Scalars["String"];
};

/** Tells us how the order has been paid */
export enum PaymentStatus {
    Cancelled = "CANCELLED",
    FullyPaid = "FULLY_PAID",
    PartiallyPaid = "PARTIALLY_PAID",
    Pending = "PENDING",
    Reserved = "RESERVED",
    Unpaid = "UNPAID",
    Aborted = "ABORTED"
}

export type Query = {
    __typename?: "Query";
    authCheck: Scalars["Boolean"];
    findFavoriteUserAccountShops?: Maybe<Array<Shop>>;
    findUserAccountByEmail?: Maybe<UserAccount>;
    findUserAccountByToken?: Maybe<Scalars["Boolean"]>;
    findUserByRefreshToken?: Maybe<UserAccount>;
    getOrdersByUserAccountId: Array<Order>;
    isShopAFavorite: Scalars["Boolean"];
    me: UserAccount;
    refetchRefetchToken: Scalars["String"];
};

export type QueryFindFavoriteUserAccountShopsArgs = {
    userId: Scalars["String"];
};

export type QueryFindUserAccountByEmailArgs = {
    email: Scalars["String"];
};

export type QueryFindUserAccountByTokenArgs = {
    token: Scalars["String"];
};

export type QueryFindUserByRefreshTokenArgs = {
    refreshToken: Scalars["String"];
};

export type QueryGetOrdersByUserAccountIdArgs = {
    endDate: Scalars["String"];
    startDate: Scalars["String"];
    userAccountId: Scalars["String"];
};

export type QueryIsShopAFavoriteArgs = {
    shopId: Scalars["String"];
    userId: Scalars["String"];
};

/** Tells us how the order has been refunded */
export enum RefundStatus {
    Failed = "FAILED",
    FullyRefunded = "FULLY_REFUNDED",
    PartiallyRefunded = "PARTIALLY_REFUNDED",
    Pending = "PENDING"
}

export type RegisterInput = {
    acceptsMarketing: Scalars["Boolean"];
    callbackUrl?: Maybe<Scalars["String"]>;
    email: Scalars["String"];
    lastName: Scalars["String"];
    name: Scalars["String"];
    password: Scalars["String"];
    phoneNumber: Scalars["String"];
    shouldLoginOnVerification?: Maybe<Scalars["Boolean"]>;
    userLanguage: UserLanguage;
};

export type ResetPasswordInput = {
    newPassword: Scalars["String"];
    token: Scalars["String"];
};

export type SelectedBundleProductItems = {
    __typename?: "SelectedBundleProductItems";
    addons: Array<Ingredient>;
    modifications?: Maybe<Modifications>;
    name: Scalars["String"];
};

export type ServiceFee = {
    __typename?: "ServiceFee";
    amount: Scalars["Float"];
    vatRate: Scalars["Float"];
};

export type Shop = {
    __typename?: "Shop";
    bannerUrl?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    imageUrl?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    publicId: Scalars["String"];
};

export type SubscriptionMeta = {
    __typename?: "SubscriptionMeta";
    name: Scalars["String"];
    subscriptionId: Scalars["String"];
    userSubscriptionId: Scalars["String"];
};

export type UpdatePasswordInput = {
    currentPassword: Scalars["String"];
    newPassword: Scalars["String"];
};

export type UserAccount = {
    __typename?: "UserAccount";
    acceptsMarketing: Scalars["Boolean"];
    contactInformation: ContactInformation;
    createdAt: Scalars["DateTime"];
    currentHashedRefreshToken?: Maybe<Scalars["String"]>;
    deleted: Scalars["Boolean"];
    disabled: Scalars["Boolean"];
    favoriteShopIds: Array<Scalars["String"]>;
    hasMigratedHistoryOrders: Scalars["Boolean"];
    id: Scalars["ID"];
    imageUrl?: Maybe<Scalars["String"]>;
    isGoogleAccount: Scalars["Boolean"];
};

export type UserAccountErrorOutput = Error | UserAccount;

export type UserAccountInput = {
    acceptsMarketing: Scalars["Boolean"];
    contactInformation: ContactInformationInput;
};

export type UserInformation = {
    __typename?: "UserInformation";
    onlineContactInformation?: Maybe<ContactInformation>;
};

/** The users selected language from LanguageProvider */
export enum UserLanguage {
    En = "en",
    Sv = "sv"
}

export type VatRateAndAmount = {
    __typename?: "VatRateAndAmount";
    amount: Scalars["Float"];
    vatRate: Scalars["Float"];
};

/** The type of an online order */
export enum WebOrderType {
    Basic = "BASIC",
    CateringDelivery = "CATERING_DELIVERY",
    CateringTakeAway = "CATERING_TAKE_AWAY",
    Express = "EXPRESS",
    HomeDelivery = "HOME_DELIVERY"
}

export type ErrorResultsFragment = { __typename?: "Error" } & Pick<Error, "path" | "message">;

export type UserAccountResultsFragment = { __typename?: "UserAccount" } & Pick<
    UserAccount,
    "id" | "imageUrl" | "createdAt" | "isGoogleAccount" | "acceptsMarketing" | "hasMigratedHistoryOrders"
> & {
        contactInformation: { __typename?: "ContactInformation" } & Pick<
            ContactInformation,
            "email" | "lastName" | "name" | "phoneNumber" | "addressLine" | "postCode" | "city"
        >;
    };

export type RegisterUserAccountMutationMutationVariables = Exact<{
    input: RegisterInput;
}>;

export type RegisterUserAccountMutationMutation = { __typename?: "Mutation" } & {
    registerUserAccount?: Maybe<Array<{ __typename?: "Error" } & Pick<Error, "path" | "message">>>;
};

export type LogoutUserAccountMutationMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutUserAccountMutationMutation = { __typename?: "Mutation" } & Pick<Mutation, "logoutUserAccount">;

export type UserAccountUpdatePasswordMutationMutationVariables = Exact<{
    input: UpdatePasswordInput;
}>;

export type UserAccountUpdatePasswordMutationMutation = { __typename?: "Mutation" } & {
    userAccountUpdatePassword?: Maybe<{ __typename?: "Error" } & Pick<Error, "path" | "message">>;
};

export type UserAccountForgotPasswordMutationMutationVariables = Exact<{
    email: Scalars["String"];
    userLanguage: UserLanguage;
}>;

export type UserAccountForgotPasswordMutationMutation = { __typename?: "Mutation" } & {
    userAccountForgotPassword?: Maybe<{ __typename?: "Error" } & Pick<Error, "path" | "message">>;
};

export type UpdateUserAccountMutationMutationVariables = Exact<{
    input: UserAccountInput;
}>;

export type UpdateUserAccountMutationMutation = { __typename?: "Mutation" } & {
    updateUserAccount: Array<
        | ({ __typename?: "Error" } & ErrorResultsFragment)
        | ({ __typename?: "UserAccount" } & UserAccountResultsFragment)
    >;
};

export type ResetUserAccountPasswordMutationMutationVariables = Exact<{
    input: ResetPasswordInput;
    userLanguage: UserLanguage;
}>;

export type ResetUserAccountPasswordMutationMutation = { __typename?: "Mutation" } & {
    resetUserAccountPassword?: Maybe<{ __typename?: "Error" } & Pick<Error, "path" | "message">>;
};

export type ImportOrdersToUserAccountMutationVariables = Exact<{
    email: Scalars["String"];
}>;

export type ImportOrdersToUserAccountMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "importOrdersToUserAccount"
>;

export type AddShopToFavoritesMutationVariables = Exact<{
    favoriteShopIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type AddShopToFavoritesMutation = { __typename?: "Mutation" } & Pick<Mutation, "addShopToFavorites">;

export type VerifyUserAccountMutationMutationVariables = Exact<{
    token: Scalars["String"];
    shouldLogin: Scalars["Boolean"];
}>;

export type VerifyUserAccountMutationMutation = { __typename?: "Mutation" } & {
    verifyUserAccount: Array<
        | ({ __typename?: "Error" } & ErrorResultsFragment)
        | ({ __typename?: "UserAccount" } & UserAccountResultsFragment)
    >;
};

export type DeleteUserAccountMutationMutationVariables = Exact<{
    userLanguage: UserLanguage;
}>;

export type DeleteUserAccountMutationMutation = { __typename?: "Mutation" } & Pick<Mutation, "deleteUserAccount">;

export type FindUserAccountByEmailQueryQueryVariables = Exact<{
    email: Scalars["String"];
}>;

export type FindUserAccountByEmailQueryQuery = { __typename?: "Query" } & {
    findUserAccountByEmail?: Maybe<{ __typename?: "UserAccount" } & Pick<UserAccount, "id">>;
};

export type FindUserByRefreshTokenQueryQueryVariables = Exact<{
    refreshToken: Scalars["String"];
}>;

export type FindUserByRefreshTokenQueryQuery = { __typename?: "Query" } & {
    findUserByRefreshToken?: Maybe<{ __typename?: "UserAccount" } & UserAccountResultsFragment>;
};

export type FindFavoriteUserAccountShopsQueryVariables = Exact<{
    userId: Scalars["String"];
}>;

export type FindFavoriteUserAccountShopsQuery = { __typename?: "Query" } & {
    findFavoriteUserAccountShops?: Maybe<
        Array<{ __typename?: "Shop" } & Pick<Shop, "id" | "imageUrl" | "bannerUrl" | "name" | "publicId">>
    >;
};

export type FindFavoriteUserAccountShopIdsQueryVariables = Exact<{
    userId: Scalars["String"];
}>;

export type FindFavoriteUserAccountShopIdsQuery = { __typename?: "Query" } & {
    findFavoriteUserAccountShops?: Maybe<Array<{ __typename?: "Shop" } & Pick<Shop, "id">>>;
};

export type IsShopAFavoriteQueryQueryVariables = Exact<{
    userId: Scalars["String"];
    shopId: Scalars["String"];
}>;

export type IsShopAFavoriteQueryQuery = { __typename?: "Query" } & Pick<Query, "isShopAFavorite">;

export type RefetchRefetchTokenQueryQueryVariables = Exact<{ [key: string]: never }>;

export type RefetchRefetchTokenQueryQuery = { __typename?: "Query" } & Pick<Query, "refetchRefetchToken">;

export type AuthCheckQueryQueryVariables = Exact<{ [key: string]: never }>;

export type AuthCheckQueryQuery = { __typename?: "Query" } & Pick<Query, "authCheck">;

export type FindUserAccountByTokenQueryQueryVariables = Exact<{
    token: Scalars["String"];
}>;

export type FindUserAccountByTokenQueryQuery = { __typename?: "Query" } & Pick<Query, "findUserAccountByToken">;

export type GetOrdersByUserAccountIdQueryQueryVariables = Exact<{
    userAccountId: Scalars["String"];
    startDate: Scalars["String"];
    endDate: Scalars["String"];
}>;

export type GetOrdersByUserAccountIdQueryQuery = { __typename?: "Query" } & {
    getOrdersByUserAccountId: Array<
        { __typename?: "Order" } & Pick<
            Order,
            | "id"
            | "orderType"
            | "receiptNumber"
            | "shopName"
            | "shopId"
            | "totalAmount"
            | "purchaseDate"
            | "deliveryFee"
        > & {
                serviceFee?: Maybe<{ __typename?: "ServiceFee" } & Pick<ServiceFee, "amount" | "vatRate">>;
                subscriptionMeta?: Maybe<
                    { __typename?: "SubscriptionMeta" } & Pick<
                        SubscriptionMeta,
                        "name" | "userSubscriptionId" | "subscriptionId"
                    >
                >;
            }
    >;
};
